import queryString from 'query-string';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import type { BaseUrl } from '@atlassian/jira-shared-types/src/general.tsx';
import type { Response } from './model.tsx';

export const createUrl = (
	baseUrl: BaseUrl,
	addonKey: string,
	moduleKey: string,
	queryParams: {
		[key: string]: string;
	},
): string =>
	`${baseUrl}/plugins/servlet/ac/${addonKey}/${moduleKey}?classifier=json&${queryString.stringify(
		queryParams,
	)}`;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	baseUrl: BaseUrl,
	addonKey: string,
	moduleKey: string,
	queryParams: {
		[key: string]: string;
	},
): Promise<Response> =>
	fetchJson(createUrl(baseUrl, addonKey, moduleKey, queryParams), {
		method: 'POST',
	});
