import React from 'react';
import Spinner from '@atlassian/jira-common-components-spinner/src/view.tsx';
import LoginRedirect from '@atlassian/jira-common-navigation/src/login-redirect/index.tsx';
import { ErrorFlagRenderer } from '@atlassian/jira-error-boundary-flag-renderer/src/ErrorBoundaryFlag.tsx';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { DocumentTitle } from '@atlassian/jira-global-document-title/src/DocumentTitle.tsx';
import SubmitApdex from '@atlassian/jira-providers-spa-apdex-analytics/src/submit-apdex-mark/index.tsx';
import { toBaseUrl } from '@atlassian/jira-shared-types/src/general.tsx';
import { SubProductUpdater } from '@atlassian/jira-spa-apps-common/src/analytics-sub-product/sub-product-updater/index.tsx';
import { MarkProductStart } from '@atlassian/jira-spa-performance-breakdown/src/utils/mark-product-start/index.tsx';
import { SpaStatePageReady } from '@atlassian/jira-spa-state-controller/src/components/main.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import type { Props } from './types.tsx';
import { jiraConnectPageLoad } from './utils/performance-analytics/index.tsx';

const ErrorComponent = ({ error }: { error: Error }) => (
	<ErrorFlagRenderer id="jira-connect" error={error} />
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default ({
	ConnectIframeLegacyApp,
	prefetchResult,
	baseUrl,
	addonKey,
	moduleKey,
	queryParams,
	onLoadingComplete,
	onTimeout,
	onError,
}: Props) => {
	const { error: prefetchError, loading, data } = prefetchResult;
	const { atlassianAccountId } = useTenantContext();

	const status =
		data?.status ||
		(prefetchError instanceof FetchError && prefetchError.statusCode) ||
		(prefetchError instanceof Error &&
			prefetchError.name === 'FetchError' &&
			'statusCode' in prefetchError &&
			prefetchError.statusCode);

	if (status === 401 && !atlassianAccountId) {
		return <LoginRedirect />;
	}

	if (prefetchError || (status && status >= 400)) {
		return <ErrorFlagRenderer id="jira-connect" error={prefetchError} />;
	}

	if (loading) {
		return <Spinner />;
	}

	const title = (data && data.title) || '';

	return (
		<>
			<MarkProductStart />
			{data ? <DocumentTitle title={title} /> : null}
			<ConnectIframeLegacyApp
				addonKey={addonKey}
				moduleKey={moduleKey}
				baseUrl={toBaseUrl(baseUrl)}
				prefetchResult={data}
				frameHeight="100%"
				frameWidth="100%"
				TimeoutIndicator={ErrorComponent}
				LoadingIndicator={Spinner}
				queryParams={queryParams}
				onLoadingComplete={onLoadingComplete}
				onTimeout={onTimeout}
				onError={onError}
			/>
			<SpaStatePageReady />
			{/* @ts-expect-error - TS2769 - No overload matches this call. */}
			<SubmitApdex
				appName="jira-connect"
				isExpectedToHaveSsr
				metric={jiraConnectPageLoad}
				extra={{ moduleType: data?.moduleType }}
			/>
			<SubProductUpdater subProduct="addon" />
		</>
	);
};
