import type { BaseUrl } from '@atlassian/jira-shared-types/src/general.tsx';
import fetchModuleConfiguration from '../rest/index.tsx';
import type { AppDescriptor } from '../types.tsx';
import { transformResponse } from './transformers.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default async (
	baseUrl: BaseUrl,
	addonKey: string,
	moduleKey: string,
	queryParams: {
		[key: string]: string;
	} = {},
): Promise<AppDescriptor> =>
	fetchModuleConfiguration(baseUrl, addonKey, moduleKey, queryParams).then(transformResponse);
