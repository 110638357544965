import React, { type ComponentType, useCallback, useMemo } from 'react';
import '@atlaskit/css-reset';
import { AnalyticsSource } from '@atlassian/jira-analytics-web-react/src/components/decorators.tsx';
import { createAttributes } from '@atlassian/jira-forge-ui-analytics/src/common/utils/create-attributes/index.tsx';
import {
	useAnalyticsEvents,
	fireOperationalAnalytics,
	fireScreenAnalytics,
	FireOperationalAnalytics,
	SCREEN,
} from '@atlassian/jira-product-analytics-bridge';
import { connectGeneralResources } from '@atlassian/jira-router-resources-connect-general/src/index.tsx';
import JiraConnect from '@atlassian/jira-spa-apps-connect-common/src/index.tsx';
import { withRouter, type RouteContext, useResource } from '@atlassian/react-resource-router';

type Props = RouteContext;

export const JiraConnectGeneralPage = ({ query, match }: Props) => {
	const baseUrl = '';

	const addonKey = match.params.addonKey || '';
	const moduleKey = match.params.moduleKey || '';

	const { createAnalyticsEvent } = useAnalyticsEvents();
	const state = useResource(connectGeneralResources);

	const moduleType = state?.data?.moduleType;

	const analyticsAttributes = useCallback(() => createAttributes(), []);

	const attributes = useMemo(
		() => ({
			addonKey,
			moduleKey,
			moduleType,
			...analyticsAttributes(),
		}),
		[addonKey, moduleKey, moduleType, analyticsAttributes],
	);

	const onLoadingComplete = useCallback(() => {
		fireScreenAnalytics(createAnalyticsEvent({}), attributes);
	}, [attributes, createAnalyticsEvent]);

	const onTimeout = useCallback(() => {
		fireOperationalAnalytics(
			createAnalyticsEvent({}),
			'JiraConnectGeneralPage timeout',
			attributes,
		);
	}, [createAnalyticsEvent, attributes]);

	const onError = useCallback(() => {
		fireOperationalAnalytics(createAnalyticsEvent({}), 'JiraConnectGeneralPage failed', attributes);
	}, [createAnalyticsEvent, attributes]);

	return (
		<>
			<FireOperationalAnalytics
				key={`${addonKey}-${moduleKey}-${JSON.stringify(query)}`}
				action="initialized"
				actionSubject="JiraConnectGeneralPage"
				actionSubjectId={`connect-iFrame-${addonKey}-${moduleKey}`}
				attributes={attributes}
			/>
			<JiraConnect
				// @ts-expect-error - TS2322 - Type '{ prefetchResult: UseResourceHookResponse<any>; baseUrl: string; addonKey: string; moduleKey: string; queryParams: Query; }' is not assignable to type 'IntrinsicAttributes & Diff<RefAttributes<any> | (RefAttributes<any> & { children?: ReactNode; }), Partial<RefAttributes<any> | (RefAttributes<...> & { ...; })>> & RefAttributes<...> & { ...; }'.
				prefetchResult={state}
				baseUrl={baseUrl}
				addonKey={addonKey}
				moduleKey={moduleKey}
				queryParams={query}
				onTimeout={onTimeout}
				onError={onError}
				onLoadingComplete={onLoadingComplete}
			/>
		</>
	);
};

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export default withRouter(
	AnalyticsSource(
		'JiraConnectGeneralPage',
		SCREEN,
	)(
		// @ts-expect-error - Argument of type '({ query, match }: Props) => JSX.Element' is not assignable to parameter of type 'ComponentType<Record<any, any>>'.
		JiraConnectGeneralPage,
	),
) as ComponentType<Record<any, any>>; // eslint-disable-line @typescript-eslint/no-explicit-any
